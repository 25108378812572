@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: #D0CADF;

    --card: 0 0% 100%;
    --card-foreground: #D0CADF;
 
    --popover: 0 0% 100%;
    --popover-foreground: #D0CADF;
 
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
 
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
 
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
 
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;

    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: #0F0B17;
    --foreground: #413A6F;
 
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
 
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
 
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
 
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
 
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
 
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
 
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.nav_font.active{
  text-shadow: 0 0 1px currentColor;
}


.custom-blur{
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

/* project carousel css */

.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 90%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
}
.embla__slide__img {
  border-radius: 1.8rem;
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
.embla__parallax {
  border-radius: 1.8rem;
  height: 100%;
  overflow: hidden;
}
.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.embla__parallax__img {
  max-width: none;
  flex: 0 0 calc(115% + (var(--slide-spacing) * 2));
  object-fit: cover;
}

/* custom scroll */
::-webkit-scrollbar {
  width: 5px; 
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #00C6FB, #005BEA);
}

.closed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.open {
  max-height: 1000px; 
  overflow: hidden;
  transition: max-height 0.4s ease-in;
}
